<template>
  <div>
    <v-card class="mr-2 mt-4 ml-4 mb-4 pb-0">
      <v-card-title class="text-h10">
        <h3>Abone listesi</h3>
      </v-card-title>
      <v-divider />
      <v-card-text class="pb-0 pt-0">
        <v-data-table
          dense
          :items-per-page.sync="itemsPerPage"
          :server-items-length="itemsLength"
          :headers="sayacheaders"
          :items="sayacdesserts"
          hide-default-footer
        />
      </v-card-text>
    </v-card>
    <v-card class="mr-2 mt-4 ml-4 mb-4 pb-0">
      <v-card-title class="text-h10">
        <h3>Saatlik Üretim / Tüketim Bilgileri</h3>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="d-flex">
          <div class="d-flex mt-2">
            <v-btn
              style="width: 80px; margin-left: 8px"
              icon
              color="indigo"
              @click="charttype = 'graph'"
            >
              <v-icon>mdi-chart-line</v-icon>Grafik
            </v-btn>

            <v-btn
              style="width: 80px"
              icon
              color="indigo"
              @click="charttype = 'table'"
            >
              <v-icon>mdi-table</v-icon>Tablo
            </v-btn>
          </div>
          <v-col cols="6" sm="2" md="2" class="pr-3 mb-0 pb-0">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :close-on-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              :rules="dateRules"
              :nudge-right="40"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  :label="daterangetext"
                  :placeholder="all"
                  class="col-10 mb-0 mt-0 pt-0 pb-0"
                  hide-details
                  readonly
                  outlined
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="dates"
                :selected-items-text="selecteditemstext"
                :locale="appLanguage"
                range
                :max="maxDate"
                :min="mindate"
              >
                <div class="">
                  <v-btn class="ml-2" small color="error" @click="cancelF">
                    {{ $t("button.b_cancel") }}
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    small
                    color="success"
                    @click="save(dates)"
                  >
                    {{ $t("button.b_save") }}
                  </v-btn>
                </div>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" class="pr-3">
            <v-select
              v-model="ososSayacModel"
              label="Sayaç Seç:"
              dense
              attach=""
              outlined
              hide-details
              :items="ososSayacList"
            />
          </v-col>
          <v-col cols="2" class="pr-3" v-if="charttype == 'table'">
            <v-select
              v-model="headersColumn"
              :items="headersList"
              :menu-props="{ maxHeight: '400' }"
              label="Kolon Seç:"
              multiple
              dense
              outlined
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <span
                  v-if="index === 0"
                  style="max-width: 120px"
                  class="d-inline-block text-truncate"
                  >{{ item.text }}</span
                >
                <span v-if="index === 1" class="grey--text text-caption"
                  >(+{{ headersColumn.length - 1 }})</span
                >
              </template>
            </v-select>
          </v-col>

          <v-col cols="3" sm="1" md="1" class="pr-3">
            <v-btn color="primary" @click="getCurrent"> Filtrele </v-btn>
          </v-col>
        </div>

        <div v-if="charttype == 'graph'">
          <div id="compsumptionchart" />
        </div>

        <div v-else>
          <v-data-table
            dense
            :items-per-page.sync="itemsPerPage"
            :server-items-length="itemsLength"
            :footer-props="{
              'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
              showFirstLastPage: true,
              itemsPerPageText: 'Limit',
            }"
            :options.sync="options"
            :headers="headers"
            :page.sync="currentPage"
            :loading="loading"
            loading-text="Yükleniyor... Lütfen bekleyiniz"
            :items="desserts"
            class="elevation-1"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getConsumption } from "@/api/Request/osos";
import moment from "moment";
import i18n from "@/locale";
import * as echarts from "echarts";

export default {
  data: () => ({
    itemsPerPage: 10,
    itemsLength: 10,
    headers: [],
    menu: false,
    daterangetext: i18n.t("DateRange"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],
    desserts: [],
    search: "",
    checkbox: true,
    appLanguage: "tr",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,
    modal2: false,
    currentPage: 1,
    loading: false,
    options: {},
    params: {
      condiniton: {},
    },
    charttype: "graph",

    tab: null,
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    startDate: moment().format("YYYY-MM-01"),
    finishDate: moment().add(1, "day").format("YYYY-MM-DD"),
    sayacheaders: [],
    headersColumn: [],
    headersList: [],
    ososSayacModel: 0,
  }),

  computed: {
    ososSayacList() {
      const temp = this.$store.getters.ososSayacList;
      const sayacList = [];
      if (temp.length > 0) {
        let index = 0;
        temp.forEach((item) => {
          console.log(item);
          sayacList.push({
            text: item.Title,
            value: index,
          });
          // eslint-disable-next-line no-plusplus
          index++;
        });
        this.getCurrent();
      }
      return sayacList;
    },
    sayacdesserts() {
      return this.$store.getters.ososSayacList;
    },
    chartList() {
      return [
        { text: "Grafik", value: "graph" },
        { text: "Tablo", value: "table" },
      ];
    },
    computedStartDateFormatted() {
      return this.formatDate(this.startDate);
    },
    computedFinishDateFormatted() {
      return this.formatDate(this.finishDate);
    },
    dateRangeText() {
      const dateArr = this.dates;
      if (this.dates.length > 1) {
        if (dateArr[0] > dateArr[1]) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[1]} ` + "<p>" + `  ${dateArr[0]}`;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[0]} ` + "<p>" + `  ${dateArr[1]}`;
        }
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.selecteditemstext = this.dates[0];
      }

      return `${dateArr[0]} / ${dateArr[1]}`;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.params.condiniton.skip = (page - 1) * itemsPerPage;
        this.params.condiniton.limit = itemsPerPage;
        this.getCurrent();
      },
    },
    headersColumn() {
      this.headers = [];
      this.headersList.forEach((item) => {
        if (this.headersColumn.indexOf(item.value) > -1) {
          this.headers.push(item);
        }
      });
    },
  },
  created() {
    this.selecteditemstext = `${this.startDate}/${this.finishDate}`;
    this.dates = [this.startDate, this.finishDate];
    this.$store.dispatch("ososSayac");
    this.params = {
      condiniton: {
        startDate: this.startDate,
        finishDate: this.finishDate,
      },
    };
  },
  mounted() {
    this.sayacList();
  },

  methods: {
    selectedSayac() {
      return this.sayacdesserts[this.ososSayacModel];
    },
    sayacList() {
      this.sayacheaders = [
        { text: i18n.t("osos.Installation"), value: "Installation" },
        { text: i18n.t("osos.Meterpoint"), value: "Meterpoint" },
        { text: i18n.t("osos.Title"), value: "Title" },
        { text: i18n.t("global.Adress"), value: "Adress" },
        { text: i18n.t("global.Group"), value: "Group" },
        { text: i18n.t("osos.Multiplier"), value: "Multiplier" },
        { text: i18n.t("global.TariffCode"), value: "TariffCode" },
        { text: i18n.t("global.InstalledPower"), value: "InstalledPower" },
        { text: i18n.t("global.Accord"), value: "Accord" },
      ];
    },
    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format("YYYY-MM-DD");
      this.startDate = moment(daterange[0]._d).format("YYYY-MM-DD");
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("DateRange");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("DateRange")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },

    getCurrent() {
      const sayac = this.selectedSayac();
      this.params.condiniton.startDate = this.startDate;
      this.params.condiniton.finishDate = this.finishDate;
      this.params.condiniton.Installation = sayac.Installation;
      getConsumption(this.params).then((r) => {
        if (this.charttype === "graph") {
          this.setOpt(r);
        } else {
          this.setTableDate(r);
        }
      });
    },
    setTableDate(res) {
      this.itemsLength = res.data.summary.totalRecordsCount;
      this.desserts = res.data.data;
      this.loading = false;
      this.headersList = res.data.keys;

      this.headers = this.headersList;
      if (this.headersColumn.length === 0) {
        this.headersColumn = this.headersList.map((item) => item.value);
      }
    },

    setOpt(_rawData) {
      const chartDom = document.getElementById("compsumptionchart");
      const myChart = echarts.init(chartDom);
      const sortType = "date";
      const colors = ["#5470C6", "#91CC75", "#EE6666"];
      const res = _rawData.data.data.sort((a, b) => {
        if (a[sortType] < b[sortType]) return -1;
        if (a[sortType] > b[sortType]) return 1;
        return 0;
      });

      const series = [];
      const xAxis = [];

      const measures = [
        { text: "Aktif Çekiş", value: "ActiveIn" },
        { text: "Aktif Veriş", value: "ActiveOut" },
        { text: "Reaktif İndüktif Çekiş", value: "ReactiveInductiveIn" },
        { text: "Reaktif Kapasitif Çekiş", value: "ReactiveCapasitiveIn" },
        { text: "Reaktif İndüktif Veriş", value: "ReactiveInductiveOut" },
        { text: "Reaktif Kapasitif Veriş", value: "ReactiveCapasitiveOut" },
      ];

      let tmp = 0;

      measures.forEach((measure) => {
        const seriesData = [];
        Object.keys(res).forEach((key) => {
          if (tmp === 0) {
            xAxis.push(res[key].date);
          }

          seriesData.push(res[key][measure.value]);
        });

        series.push({
          name: measure.text,
          type: "line",
          data: seriesData,
          color: colors[tmp],
        });
        tmp += 1;
      });

      const option = {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: false,
            },
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            height: 15,
          },
          {
            type: "slider",
            height: 15,
            bottom: 0,
          },
        ],

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xAxis,

          silent: false,
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          show: true,
        },
        series,
      };

      myChart.setOption(option, true);
    },
  },
};
</script>
 <style>
#compsumptionchart {
  margin-top: 10px;
  height: 100%;
  width: 100%;
  min-height: 400px;
}
</style>
